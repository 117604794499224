
<template>
 <div class="">
 	<el-pagination
 	  layout="slot,prev, pager, next"
 	  background
	  :current-page="page" :page-size="pageSize" 
 	  @current-change="handleChange"
	   :hide-on-single-page="true"
 	  :total="total">
 	</el-pagination>
 </div>
</template>

<script>
export default {
	props:{
			total:Number,
			page:Number,
			pageSize:Number
	},
    data() {
        return {
		};
    },
	components:{
		
	},
    created() {

    },
    mounted() {

    },
    methods: {
		handleChange(val){
			// console.log(val)
			this.$emit('handleList',val)
		}
    }
};
</script>

<style scoped lang='less'>
	::v-deep{
		//未选中
		.el-pagination.is-background .el-pager li:not(.disabled) {
			  background-color: rgba(255,255,255,0); 
			  color: #960;
		}
		//选中
		.el-pagination.is-background .el-pager li:not(.disabled).active {
			  background-color: rgba(255,255,255,0); 
			  color: #81a849;
		}
		//指向箭头
		.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev{
			background-color:#e1e1e1;
			border-radius: 50%;
		}
		
	}
.el-pagination{
	background-color: rgba(255,255,255,0);
	text-align: center;
	margin-top: 50px;
	
}


</style>