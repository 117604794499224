<template>
    <div class="no">
      <img src="../../assets/no.png" alt="">
      <div class="text">
          暂无数据
      </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        };
    },
    created() {

    },
    mounted() {

    },
    methods: {

    }
};
</script>

<style scoped lang='less'>
.no{
    text-align: center;
}
img{
    width: 250px;
    height: 168px;
    margin: 40px 0;
}
.text{
  font-size: 16px;
font-family: Microsoft YaHei;
font-weight: 400;
color: #666666;  
}
</style>
