<template>
    <div v-if="imgList.length>0">
        <div class="img" >
          	    <img v-if="imgSrc" :src="imgSrc" >			   
          </div>
		  <el-carousel arrow="always"  indicator-position="none" :interval="4000" type="card" height="200px" @change="handleChange">
		    <el-carousel-item v-for="(item,index) in imgList" :key="index" >
		     <img :src="'/gsmm/api/v1/'+item.url" style="width:240px;height:180px" @click="handleClick(item.url)">
		    </el-carousel-item>
		  </el-carousel>
          <Background :style="{'width':'calc(100% + 20px)','margin-top':'25px','margin-left':'-10px','margin-bottom':'-15px'}" />
    </div>
</template>

<script>

import {mapActions,mapState,mapMutations} from 'vuex'
import Background from '../components/background.vue'
export default {
    props:{
        id:Number
    },
    data() {
        return {
                    
        };
    },
    components:{
      Background  
    },
    computed:{
        ...mapState(['imgList','imgSrc'])
    },
    mounted() {
  
             this.handleimgList(this.id)
     
       
    },
    methods: {
        ...mapActions(['handleimgList']),
        ...mapMutations(['setimgSrc']),
           handleClick(url){
               this.setimgSrc(url)
               
        },
        handleChange(e){
             this.setimgSrc(this.imgList[e].url)
            console.log(e)
        }
    }
};
</script>

<style scoped lang='less'>
.img{
		margin-top: 50px;
		padding:0 20%;
		img{
			width:100%
		}
	}
    .el-carousel{
		text-align: center;
		/* width: 1000px; */
		margin-top: 50px;
	}
</style>
