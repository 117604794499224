<template>
    <div class="bg1" :style="style">
            <div class="img"></div>
    </div>
</template>

<script>
export default {
    props:{
      style:Object
    },
    data() {
        return {

        };
    },
    created() {

    },
    mounted() {

    },
    methods: {

    }
};
</script>

<style scoped lang='less'>
.bg1{  
    .img{
        background: url('../../assets/hr.png');
        width: 100%;
        height:21px;
    }
}
</style>
